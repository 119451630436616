import React from 'react';

export default function DownloadStats() {
    return (

        <div className="stats grid-flow-row md:grid-flow-col bg-opacity-0 sm:place-content-center w-[100%] mb-5 md:mt-5">

            <div className="stat">
                <div className="stat-figure">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bb5050" viewBox="0 0 512 512" className="inline-block w-5 stroke-current"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                </div>
                <div className="stat-title">Total Downloads</div>
                <div className="">0k</div>
                <div className="stat-desc">↗︎ Total Count </div>
            </div>

            <div className="stat">
                <div className="stat-figure">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bb5050" viewBox="0 0 512 512" className="inline-block w-5 stroke-current"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" /></svg>
                </div>
                <div className="stat-title">New Users (Month)</div>
                <div className="">0k</div>
                <div className="stat-desc">↗︎ increment (0%)</div>
            </div>

        </div>

    );
}
