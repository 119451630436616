import React, { useRef, useEffect, useState } from 'react';
import './Navbar.css';

export default function Navbar() {

    const styleOfNav = {
        // width of screen - 20px 
        width: "calc(100vw - 20px)",
    };

    const navContentRef = useRef(null);
    const [showNavContent, setShowNavContent] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (navContentRef.current && !navContentRef.current.contains(event.target)) {
                const checkbox = document.getElementById('checkbox0');

                if (event.target.id !== 'close') {
                    setShowNavContent(false);
                    checkbox.checked = false;
                    document.getElementById('mainNav').classList.remove('z-40');
                    document.getElementById('mainNav').classList.add('-z-10');

                    console.log(event.target);
                }
                else {
                    console.log('close');
                }

            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [navContentRef]);

    const isOpenedController = () => {
        const checkbox = document.getElementById('checkbox0');
        setShowNavContent(checkbox.checked);

        if (checkbox.checked) {
            document.getElementById('mainNav').classList.add('z-40');
            document.getElementById('mainNav').classList.remove('-z-10');

        }
        else {
            document.getElementById('mainNav').classList.remove('z-40');
            document.getElementById('mainNav').classList.add('-z-10');
        }
    }

    const tabControl = (event) => {
        const tabs = document.getElementsByClassName('tab');
        const tab0 = document.getElementById('tab0');
        const tab1 = document.getElementById('tab1');
        const tab2 = document.getElementById('tab2');

        for (let i = 0; i < tabs.length; i++) {
            tabs[i].classList.remove('tab-active');
            tabs[i].classList.add('text-white');
        }

        event.target.classList.add('tab-active');
        event.target.classList.remove('text-white');
        event.target.classList.add('text-primary');

        if (event.target.innerText === 'Navs') {
            tab0.classList.remove('hidden');
            tab1.classList.add('hidden');
            tab2.classList.add('hidden');
        }
        else if (event.target.innerText === 'Projects') {
            tab0.classList.add('hidden');
            tab1.classList.remove('hidden');
            tab2.classList.add('hidden');
        }
        else if (event.target.innerText === 'Feedback') {
            tab0.classList.add('hidden');
            tab1.classList.add('hidden');
            tab2.classList.remove('hidden');
        }
    }

    return (
        <>
            <label id='label0' className="btn-circle btn-primary swap swap-rotate fixed bottom-5 right-5 md:top-5 md:left-5 z-50">

                {/* <!-- this hidden checkbox controls the state --> */}
                <input id='checkbox0' onClick={isOpenedController} type="checkbox" />

                {/* <!-- hamburger icon --> */}
                <svg id='close' className="swap-off fill-white" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" /></svg>

                {/* <!-- close icon --> */}
                <svg id='close' className="swap-on fill-white" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><polygon id="close" points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" /></svg>

            </label>
            <nav className="fixed bottom-0 right-0 md:left-0 md:top-0 -z-10" id="mainNav">

                {/* <!-- this div is hidden by default --> */}
                <div
                    className={`scale-transition ${showNavContent ? 'show' : ''} 
                flex flex-col
                 bg-black border-primary 
                border-2 z-40 opacity-90 p-6 
                rounded-xl m-3 md:m-1  md:pl-24
                text-primary`}
                    style={styleOfNav}
                    id="navContent"
                    ref={navContentRef}
                >
                    <div className="tabs mb-3">
                        <div tabIndex={0} className="tab tab-lifted border-white tab-active text-primary" onClick={tabControl}>Navs</div>
                        <div tabIndex={1} className="tab tab-lifted text-white border-white" onClick={tabControl}>Projects</div>
                        <div tabIndex={2} className="tab tab-lifted text-white border-white" onClick={tabControl}>Feedback</div>
                    </div>

                    {/* tab0 contains inpage links */}
                    <div id='tab0' className='flex flex-col md:flex-row'>
                        <a href="/main" className="btn-ghost rounded-btn p-1 px-2">Home</a>
                        <a href="/main" className="btn-ghost rounded-btn p-1 px-2">Projects</a>
                        <a href="/main" className="btn-ghost rounded-btn p-1 px-2">Team</a>
                        <a href="/main" className="btn-ghost rounded-btn p-1 px-2">Contact & About</a>
                    </div>

                    {/* tab1 contains projects and links */}
                    <div id='tab1' className='flex-col md:flex-row hidden'>
                        <a href="/main" className="btn-ghost rounded-btn p-1 px-2">Pomodoro Shell</a>
                    </div>

                    {/* tab2 is a form for feedbacks */}
                    <div id='tab2' className='hidden'>
                        <form className="form-control">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input type="text" placeholder="John Doe" className="input input-primary input-bordered" />
                            <label className="label">
                                <span className="label-text">Email</span>
                            </label>
                            <input type="email" placeholder="John@doe.com" className="input input-primary input-bordered" />
                            <label className="label">
                                <span className="label-text">Message</span>
                            </label>
                            <textarea rows="3" className="textarea h-24 textarea-primary textarea-bordered"></textarea>
                            <div className="form-control mt-3">
                                <button className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>


                    {/* horizontal line */}
                    <div className="w-[100%] h-[1px] bg-white my-3 mb-4"></div>

                    {/* 4 grid buttons */}
                    <div className='flex flex-row'>
                        <a href="/main" className="btn btn-primary rounded-btn p-1 px-2 mr-3 w-[40%] md:w-24">Privacy Policy</a>
                        <a href="/main" className="btn btn-primary btn-outline rounded-btn p-1 px-2 w-[40%] md:w-24">Terms of Service</a>

                        {/* store the buttons for desktop */}
                        <div className='hidden md:block ml-3'>
                            <a href="/main" className="btn btn-outline rounded-btn p-1 px-2 mr-3 w-24">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={18}>
                                    <path
                                        fill='white'
                                        d="M255.9 120.9l9.1-15.7c5.6-9.8 18.1-13.1 27.9-7.5 9.8 5.6 13.1 18.1 7.5 27.9l-87.5 151.5h63.3c20.5 0 32 24.1 23.1 40.8H113.8c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4h52l66.6-115.4-20.8-36.1c-5.6-9.8-2.3-22.2 7.5-27.9 9.8-5.6 22.2-2.3 27.9 7.5l8.9 15.7zm-78.7 218l-19.6 34c-5.6 9.8-18.1 13.1-27.9 7.5-9.8-5.6-13.1-18.1-7.5-27.9l14.6-25.2c16.4-5.1 29.8-1.2 40.4 11.6zm168.9-61.7h53.1c11.3 0 20.4 9.1 20.4 20.4 0 11.3-9.1 20.4-20.4 20.4h-29.5l19.9 34.5c5.6 9.8 2.3 22.2-7.5 27.9-9.8 5.6-22.2 2.3-27.9-7.5-33.5-58.1-58.7-101.6-75.4-130.6-17.1-29.5-4.9-59.1 7.2-69.1 13.4 23 33.4 57.7 60.1 104zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z" />
                                </svg>
                            </a>
                            <a href="/main" className="btn btn-outline rounded-btn p-1 w-24">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={18}>
                                    <path
                                        fill="white"
                                        d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
                                </svg>
                            </a>
                        </div>
                    </div>

                    {/* store buttons for mobile*/}
                    <div className='flex flex-row mt-3 md:hidden'>
                        <a href="/main" className="btn btn-outline rounded-btn p-1 px-2 mr-3 w-[40%]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20}>
                                <path
                                    fill='white'
                                    d="M255.9 120.9l9.1-15.7c5.6-9.8 18.1-13.1 27.9-7.5 9.8 5.6 13.1 18.1 7.5 27.9l-87.5 151.5h63.3c20.5 0 32 24.1 23.1 40.8H113.8c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4h52l66.6-115.4-20.8-36.1c-5.6-9.8-2.3-22.2 7.5-27.9 9.8-5.6 22.2-2.3 27.9 7.5l8.9 15.7zm-78.7 218l-19.6 34c-5.6 9.8-18.1 13.1-27.9 7.5-9.8-5.6-13.1-18.1-7.5-27.9l14.6-25.2c16.4-5.1 29.8-1.2 40.4 11.6zm168.9-61.7h53.1c11.3 0 20.4 9.1 20.4 20.4 0 11.3-9.1 20.4-20.4 20.4h-29.5l19.9 34.5c5.6 9.8 2.3 22.2-7.5 27.9-9.8 5.6-22.2 2.3-27.9-7.5-33.5-58.1-58.7-101.6-75.4-130.6-17.1-29.5-4.9-59.1 7.2-69.1 13.4 23 33.4 57.7 60.1 104zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z" />
                            </svg>
                        </a>
                        <a href="/main" className="btn btn-outline rounded-btn p-1 px-2 w-[40%]">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20}>
                                <path
                                    fill="white"
                                    d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
                            </svg>
                        </a>
                    </div>

                </div>


            </nav>
        </>
    );
}