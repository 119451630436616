import { Main } from "./pages/main_page/Main";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

const AppRoutes = [
  {
    index: true,
    element: <Main />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />
  }
];

export default AppRoutes;
