import React from 'react';
import Navbar from '../../components/Navbar';

import TopArt from './TopArt';
import Projects from './Projects';
import Team from './Team';
// import ContactAndAbout from './ContactAndAbout';
import Footer from '../../components/Footer';

export function Main() {
    return (
        <div id='upTier'>
            <Navbar />

            <TopArt />

            <Projects />

            <Team />

            <Footer />

        </div>
    );
}