import React from 'react';

export default function Footer() {
    return (
        <footer className="footer p-10 border-t-2 border-t-primary text-base-content h-screen md:h-full" style={styles.bgImage}>
            <div>

                {/* logo */}
                <svg id="vafaill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width={50}>
                    <path d="M 485 100 L 860 270 L 1000 490 L 740 660 L 475 690 L 310 900 L 235 770 L 65 675.001 L 0 415.001 C 0 415.001 95 185.001 95 185.001 L 485 100 M 525 615.001 L 490 550 L 435 540 L 300 745.001 L 320 770 L 435 625.001 L 525 615.001 M 90 485.001 L 125 630 L 205 675.001 L 235 505.001 L 90 485.001 M 815 325.001 L 750 295.001 L 765 525.001 L 810 525.001 L 905 465.001 L 815 325.001 M 605 605.001 L 705 590 L 700 580 L 585 565.001 L 605 605.001 M 475 375.001 L 570 230 L 430 295.001 L 475 375.001 M 90 390 L 275 295.001 L 290 215.001 L 145 245.001 L 90 390 M 690 510 L 670 260 L 640 250 L 510 440 L 540 490 L 690 510 M 320 455.001 L 405 470 L 430 440 L 365 330 L 340 340 L 320 455.001 M 495 185.001 L 480 175.001 L 370 195.001 L 395 235.001 L 495 185.001 M 360 530 L 305 520 L 290 630 L 360 530 M 250 440 L 260 380 L 170 425.001 L 250 440"
                        fill="#bb5050" />
                </svg>

                <p>Vafaill<br />Creating since 2023</p>
            </div>
            <div>
                <span className="footer-title">Projects</span>
                <a className="link link-hover">Pomodoro Shell</a>
            </div>
            <div>
                <span className="footer-title">Company</span>
                <a className="link link-hover">Team</a>
                <a className="link link-hover">Contact & About</a>
            </div>
            <div>
                <span className="footer-title">Legal</span>
                <a className="link link-hover">Terms of use</a>
                <a className="link link-hover">Privacy policy</a>
                <a className="link link-hover">Cookie policy</a>
            </div>
        </footer>
    );
}

const styles = {
    bgImage: {
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg.png"})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
}