import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";

import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';


import "swiper/css";
import "swiper/css/effect-cards";
import "./styles.css";


export default function Projects() {
    return (
        <div class="min-h-screen py-10 flex flex-col justify-center items-center">
            <h3 className="text-3xl font-bold text-center">Projects</h3>

            <div className='grid md:grid-flow-col gap-5 place-content-center w-[75%]'>

            </div>

            <div className="h-[1.75rem]"></div>

            <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
            >
                <SwiperSlide className='w-5'>
                    <div className="mockup-phone border-primary h-[500px]">
                        <div className="camera"></div>
                        <div className="display">
                            <div className="flex flex-col justify-center items-center w-[240px] h-[470px] phone-1" style={styles.bgImage}>
                                <label htmlFor="my-modal-0" className="text-base">pomodoro shell</label>

                                {/* button group: google play, app store, github */}
                                <div className="btn-group">
                                    <div className="btn btn-outline">
                                        <AppleIcon classname="btn btn-outline " style={{ fontSize: 30 }} />
                                    </div>
                                    <div className="btn btn-outline">
                                        <AndroidIcon style={{ fontSize: 30 }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='w-5'>
                    <div className="mockup-phone border-primary h-[500px]">
                        <div className="camera"></div>
                        <div className="display">
                            <div className="flex justify-center items-center w-[240px] h-[470px] phone-1">
                                {/* The button to open modal */}
                                <label htmlFor="my-modal-1" className="text-base">late sentinel</label>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


            </Swiper>

            <p className='text-center mt-5 text-white'>Swipe</p>

        </div>
    );
}

const styles = {
    bgImage: {
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/pomodoro-shell-ss.jpg"})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
    },
};


