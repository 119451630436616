import React from 'react';
import DownloadStats from '../../components/DownloadStats';

export default function TopArt() {
    return (
        <div class="min-h-[100svh] flex flex-col justify-center items-center border-primary px-3 border-b-2" style={styles.bgImage}>

            {/* logo */}
            <svg id="vafaill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width={150} className='mt-5'>
                <path d="M 485 100 L 860 270 L 1000 490 L 740 660 L 475 690 L 310 900 L 235 770 L 65 675.001 L 0 415.001 C 0 415.001 95 185.001 95 185.001 L 485 100 M 525 615.001 L 490 550 L 435 540 L 300 745.001 L 320 770 L 435 625.001 L 525 615.001 M 90 485.001 L 125 630 L 205 675.001 L 235 505.001 L 90 485.001 M 815 325.001 L 750 295.001 L 765 525.001 L 810 525.001 L 905 465.001 L 815 325.001 M 605 605.001 L 705 590 L 700 580 L 585 565.001 L 605 605.001 M 475 375.001 L 570 230 L 430 295.001 L 475 375.001 M 90 390 L 275 295.001 L 290 215.001 L 145 245.001 L 90 390 M 690 510 L 670 260 L 640 250 L 510 440 L 540 490 L 690 510 M 320 455.001 L 405 470 L 430 440 L 365 330 L 340 340 L 320 455.001 M 495 185.001 L 480 175.001 L 370 195.001 L 395 235.001 L 495 185.001 M 360 530 L 305 520 L 290 630 L 360 530 M 250 440 L 260 380 L 170 425.001 L 250 440"
                    fill="#bb5050" />
            </svg>
            <h1 className="text-3xl font-bold pb-7 text-primary">Vafaill</h1>


            <div className="hero">
                <div className="hero-content text-center w-[100%]">
                    <div className="w-[100%]">
                        {/* buttons group */}
                        <div className='flex flex-row my-2 justify-center'>
                            <a href="/main" className="btn btn-outline rounded-btn p-1 px-2 mr-3 w-[30%] max-w-[120px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20}>
                                    <path
                                        fill="white"
                                        d="M255.9 120.9l9.1-15.7c5.6-9.8 18.1-13.1 27.9-7.5 9.8 5.6 13.1 18.1 7.5 27.9l-87.5 151.5h63.3c20.5 0 32 24.1 23.1 40.8H113.8c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4h52l66.6-115.4-20.8-36.1c-5.6-9.8-2.3-22.2 7.5-27.9 9.8-5.6 22.2-2.3 27.9 7.5l8.9 15.7zm-78.7 218l-19.6 34c-5.6 9.8-18.1 13.1-27.9 7.5-9.8-5.6-13.1-18.1-7.5-27.9l14.6-25.2c16.4-5.1 29.8-1.2 40.4 11.6zm168.9-61.7h53.1c11.3 0 20.4 9.1 20.4 20.4 0 11.3-9.1 20.4-20.4 20.4h-29.5l19.9 34.5c5.6 9.8 2.3 22.2-7.5 27.9-9.8 5.6-22.2 2.3-27.9-7.5-33.5-58.1-58.7-101.6-75.4-130.6-17.1-29.5-4.9-59.1 7.2-69.1 13.4 23 33.4 57.7 60.1 104zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z" />
                                </svg>
                            </a>
                            <a href="https://play.google.com/store/apps/dev?id=4658493325670793365&hl=en" className="btn btn-outline rounded-btn p-1 px-2 w-[30%] max-w-[120px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20}>
                                    <path
                                        fill="white"
                                        d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
                                </svg>
                            </a>
                            <a href="https://github.com/VafaillDevs" className="btn btn-outline rounded-btn p-1 px-2 ml-3 w-[30%] max-w-[120px]">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20}>
                                    <path
                                        fill="white"
                                        d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
            </div>

            <DownloadStats />

        </div>
    );
}

const styles = {
    bgImage: {
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/bg.png"})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
}
