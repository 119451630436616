import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';

const avatars = [
    process.env.PUBLIC_URL + 'images/avatars/Berko.jpeg',
    process.env.PUBLIC_URL + 'images/avatars/Selo.jpeg',
    // Add more avatar URLs for other team members if needed
];

export default function Team() {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center py-10">
            <h3 className="text-3xl font-bold text-center mb-12 md:mb-12">Team</h3>
            <div className='grid md:grid-flow-col gap-10 my-2'>
                {personModal(0, "Berkay Aslan", "Developer", "https://www.linkedin.com/in/berkay-aslan-b13a251b5/", "https://github.com/KahlerYasla")}
                {personModal(1, "Selin Çırak", "Art / Design", "https://www.linkedin.com/in/selincirak/", "https://timrotree.artstation.com/")}
            </div>
        </div>
    )
}

function personModal(id, name, description, linkedin, portfolio) {
    return (
        <div className='flex flex-col gap-3 justify-center items-center'>
            <div className="avatar">
                <div className="w-36 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 mb-2">
                    <img src={avatars[id]} alt={name} />
                </div>
            </div>

            {/* Labels */}
            <label htmlFor={"my-modal-team" + name} className="text-primary">{name}</label>
            <label htmlFor={"my-modal-team" + name} className="">{description}</label>

            {/* The button group: linkedin, GitHub, and ArtStation */}
            <div className="btn-group gap-5">
                {/* linkedin */}
                <a href={linkedin} target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon style={{ fontSize: 35 }} />
                </a>

                {/* portfolio */}
                <a href={portfolio} target="_blank" rel="noopener noreferrer">
                    <BubbleChartOutlinedIcon style={{ fontSize: 35 }} />
                </a>

            </div>

        </div>
    );
}
